import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { LocaleService } from '@core/services/locale/locale.service';
import {
  AppPermissions,
  ButtonColor,
  ChartViewConfig,
  ContentViewDto,
  IAction,
  ModuleKeywordRootPath,
  PermissionActions,
  routeToLocaleCase,
  toCamelCase,
} from '@shared/classes';
import { BaseDashletTable } from '@shared/classes/view/BaseDashletTable';
import { AppDialogService, ExportDataService, PathResolverService } from '@shared/services';

@Component({
  selector: 'app-widget-table',
  templateUrl: './widget-table.component.html',
  styleUrl: './widget-table.component.scss',
})
export class WidgetTableComponent extends BaseDashletTable<any> implements OnInit {
  @ContentChild('headerTemplate') headerTemplate: TemplateRef<any>;
  @Input() styleClass: string = 'relative card m-0';
  @Input() title: string = 'some title';
  @Input() lazy: boolean = true;
  @Input() paginator: boolean = true;
  @Input() cardContent: ContentViewDto = {
    permissions: AppPermissions.ReadControlGroup,
    type: ContentViewDto.TypeEnum.Chart,
    targetType: 'CONTROL_OBJECTIVE',
    filterView: {
      code: 'FIL-1',
      name: 'Control Objective Aggre filter',
      filter: { projectionFields: [], filters: [] },
      aggregationBody: {
        aggregationFields: [
          {
            operation: 'COUNT',
            fieldName: 'controlClassification',
          },
        ],
        groupByFields: [{ fieldName: 'controlClassification', typeShape: 'NORMAL' }],
      },
      type: 'SEARCH',
      targetType: 'CONTROL_OBJECTIVE',
    },
    tableViewConfig: {
      columns: [
        {
          key: 'code',
          name: 'Code',
          order: 0,
          visible: true,
          groupRowsBy: null,
          appliedFilters: null,
          sort: null,
        },
        {
          key: 'name',
          name: 'Name',
          order: 0,
          visible: true,
          groupRowsBy: null,
          appliedFilters: null,
          sort: null,
        },
      ],
      showActions: true,
      showOperationActions: true,
      showGlobalFilter: true,
      showSettingsLink: true,
      showFileExportAction: true,
      showPackageExportAction: true,
      showBulkOperationAction: true,
      showDeleteAction: true,
      showEditAction: true,
      showLockAction: true,
      showActivateAction: true,
      showDeactivateAction: true,
      showStatusActions: true,
    },
    chartViewConfig: {
      chartType: ChartViewConfig.ChartTypeEnum.Doughnut,
      colorPalette: ['#fcfcfc', '#54454'],
      viewType: ChartViewConfig.ViewTypeEnum.ChartAndData,
      // scaleType: ChartViewConfig.ScaleTypeEnum.Standard,
    },
  };
  @Input() pageActions: IAction[] = [];
  @Input() tableActions: IAction[] = [];
  @Input() additionalStaticProjectionFields: string[] = [];
  @Input() setDefaultActions = true;
  @Input() showNotFoundColumns = false;
  constructor(
    private exportService1: ExportDataService,
    public appDialogService: AppDialogService,
    public router: Router,
    private pathResolverService: PathResolverService,
    public localeService: LocaleService
  ) {
    super(exportService1, appDialogService, router, pathResolverService);
  }
  ngOnInit(): void {
    this.initTable();
  }
  initTable() {
    this.mappingService = this.pathResolverService.getMappingServiceByTargetType(this.cardContent?.targetType as any);
    this.setTableConfig({
      targetType: this.cardContent?.targetType,
      cols: this.showNotFoundColumns
        ? (this.cardContent?.tableViewConfig?.columns as any)
        : this.mappingService.tableFields
            .filter(
              (x) =>
                this.cardContent?.tableViewConfig?.columns?.length > 0
                  ? this.cardContent?.tableViewConfig?.columns?.find((col) => col.key == x.key)
                  : false
              // this.cardContent?.filterView?.filter?.projectionFields?.length > 0
              //   ? this.cardContent?.filterView?.filter?.projectionFields.includes(x.key)
              //   : true
            )
            .map((x) => {
              return {
                ...x,
                name: this.localeService.translate(
                  `modules.${routeToLocaleCase(ModuleKeywordRootPath[this.cardContent?.targetType as any])}.${toCamelCase(this.cardContent?.targetType as any)}.fields.${x.key}.label`,
                  x.name
                ),
                translationKeyPrefix:
                  x.translationKeyPrefix ||
                  `modules.${routeToLocaleCase(ModuleKeywordRootPath[this.cardContent?.targetType as any])}.${toCamelCase(this.cardContent?.targetType as any)}.fields.${x.key}`,
              };
            }), //this.cardContent?.filterView?.filter?.projectionFields.includes(x.key)  //this.cardContent?.tableViewConfig?.columns?.find(col=> col.key == x.key)
      tableActions: this.tableActions,
      showActions: this.cardContent?.tableViewConfig?.showActions,
      showGlobalFilter: this.cardContent?.tableViewConfig?.showGlobalFilter,
      showOperationActions: this.cardContent?.tableViewConfig?.showOperationActions,
      showDeleteAction: this.cardContent?.tableViewConfig?.showDeleteAction,
      showEditAction: this.cardContent?.tableViewConfig?.showEditAction,
      showLockAction: this.cardContent?.tableViewConfig?.showLockAction,
      showActivateAction: this.cardContent?.tableViewConfig?.showActivateAction,
      showDeactivateAction: this.cardContent?.tableViewConfig?.showDeactivateAction,
      showStatusActions: this.cardContent?.tableViewConfig?.showStatusActions,
      showSettingsLink: this.cardContent?.tableViewConfig?.showSettingsLink,
      showFileExportAction: this.cardContent?.tableViewConfig?.showFileExportAction,
      showPackageExportAction: this.cardContent?.tableViewConfig?.showPackageExportAction,
      showBulkOperationAction: this.cardContent?.tableViewConfig?.showBulkOperationAction,
      showReviseRelationsAction: this.cardContent?.tableViewConfig?.showReviseRelationsAction,
      showAddAction: this.cardContent?.tableViewConfig?.showAddAction,
      showUnLockAction: this.cardContent?.tableViewConfig?.showUnLockAction,
      additionStaticFilters: this.cardContent?.filterView?.filter?.filters,
      additionStaticRelationFilters: this.cardContent?.filterView?.filter?.relationFilters,
      sortFields: this.cardContent?.filterView?.aggregationBody?.sortFields,

      additionalStaticProjectionFields: this.additionalStaticProjectionFields, //todo add to cardContent view
    });
    if (this.setDefaultActions) {
      this.pageActions = [
        {
          label: 'Add Item',
          id: 1,
          color: ButtonColor.Primary,
          icon: 'pi pi-plus',
          iconPos: 'left',
          command: this.addNewItem.bind(this),
          displayCommand: () => this.showAddAction,
          permission: `${PermissionActions.Create}${this.moduleKeyword}`,
        },
      ];
    }
  }
  selectedRows = [];
  @Output() onRowSelectionChange: EventEmitter<any> = new EventEmitter();
  onRowSelection(event) {
    this.selectedRows = [...event];
    this.onRowSelectionChange.emit(event);
  }
}
